import React from 'react'
import { Link } from 'gatsby'
import ThemeToggle from './theme-toggle.js'

import { Icon } from '@iconify/react'
import userOutlined from '@iconify/icons-ant-design/user-outlined'
import desktopOutlined from '@iconify/icons-ant-design/desktop-outlined'
import fileTextOutlined from '@iconify/icons-ant-design/file-text-outlined'
import mailOutlined from '@iconify/icons-ant-design/mail-outlined'

const MainNav = () => {
  return (
    <ul className='MainNav'>
      <li className='MainNav__intro'>
        <Link to='/' tabIndex='-1'>
          <span className='MainNav__name'>
            Collin Waid
          </span>
          <span className='MainNav__role'>
            Front-end Developer
          </span>
        </Link>
      </li>
      <li className='MainNav__theme-toggle'>
        <ThemeToggle />
      </li>
      <li className='MainNav__item'>
        <Link to='/' activeClassName='active'>
          <Icon className='NavIcon' icon={userOutlined} />
          About
        </Link>
      </li>
      <li className='MainNav__item'>
        <Link to='/portfolio/' activeClassName='active' partiallyActive={true}>
          <Icon className='NavIcon' icon={desktopOutlined} />
          Portfolio
        </Link>
      </li>
      {/* <li className='MainNav__item'>
        <Link to='/resume/' activeClassName='active'>
          <Icon className='NavIcon' icon={fileTextOutlined} />
          Resume
        </Link>
      </li> */}
      <li className='MainNav__item'>
        <Link to='/contact/' activeClassName='active'>
          <Icon className='NavIcon' icon={mailOutlined} />
          Contact
        </Link>
      </li>
    </ul>
  )
}

export default MainNav
