import React, { useContext } from 'react'
import ThemeContext from '../context/theme-context'

import darkModeToggleOff from '../images/icons/dark-mode-toggle-off.svg'
import darkModeToggleOn from '../images/icons/dark-mode-toggle-on.svg'

const ThemeToggle = () => {
  const theme = useContext(ThemeContext)
  const darkModeToggleImage = theme.dark ? darkModeToggleOn : darkModeToggleOff

  return (
    <div className='ThemeToggle'>
      <label className='ThemeToggle__label' htmlFor='ThemeToggle__btn'>
        Dark Mode
      </label>
      <button id='ThemeToggle__btn' className='ThemeToggle__btn' onClick={theme.toggleDark} title='Toggle dark mode' role='switch' aria-checked={theme.dark}>
        Toggle dark mode
        <img src={darkModeToggleImage} alt='' />
      </button>
    </div>
  )
}

export default ThemeToggle
