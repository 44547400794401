import React from 'react'
import PropTypes from 'prop-types'
import ThemeContext from '../context/theme-context'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import ThemeToggle from './theme-toggle.js'
import MainNav from './main-nav'

import './layout.css'
import '../styles/app.scss'

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <ThemeContext.Consumer>
          {theme => (
            <>
              <Helmet
                htmlAttributes={{
                  lang: 'en',
                  class: 'theme--' + (theme.dark ? 'dark' : 'default')
                }}
                title={data.site.siteMetadata.title}
                meta={[
                  { name: 'description', content: data.site.siteMetadata.description },
                ]}
              >
              </Helmet>
              <div className='Main'>
                <ThemeToggle />
                <nav className='Main__nav'>
                  <MainNav />
                </nav>
                <main className='Main__content'>
                  {children}
                </main>
              </div>
            </>
          )}
        </ThemeContext.Consumer>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
